<template>
  <div class="mobile-bookings-form">
    <v-row no-gutters>
      <v-col cols="12">
        <calendesk-information-message>
          {{ $trans("mobile_about_us_form_bookings_info") }}
        </calendesk-information-message>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.menu_bookings"
          :label="$trans('mobile_bookings_form_menu_bookings_label')"
          outlined
          hide-details
          maxlength="15"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.my_bookings_active_bookings"
          :label="$trans('mobile_bookings_form_menu_active_bookings_label')"
          outlined
          hide-details
          maxlength="30"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          v-model="form.my_bookings_previous_bookings"
          :label="$trans('mobile_bookings_form_menu_previous_bookings_label')"
          outlined
          hide-details
          maxlength="30"
          @input="updateTranslationInput"
          @blur="changeTranslationsBlur"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";

export default {
  name: "MobileBookingsForm",
  components: {
    CalendeskInformationMessage,
  },
  data() {
    return {
      form: {
        menu_bookings: "",
        my_bookings_active_bookings: "",
        my_bookings_previous_bookings: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getTranslations: "mobileEditor/getTranslations",
    }),
  },
  watch: {
    getTranslations() {
      this.updateFields();
    },
  },
  beforeMount() {
    this.updateFields();
  },
  methods: {
    ...mapActions({
      changeConfiguration: "mobileEditor/changeConfiguration",
      updateTranslation: "mobileEditor/updateTranslation",
    }),
    updateFields() {
      if (!this.getTranslations) return;
      this.form.menu_bookings = this.getTranslations.menu_bookings;
      this.form.my_bookings_active_bookings =
        this.getTranslations.my_bookings_active_bookings;
      this.form.my_bookings_previous_bookings =
        this.getTranslations.my_bookings_previous_bookings;
    },
    updateTranslationInput() {
      this.updateTranslation({ translations: { ...this.form } });
    },
    changeTranslationsBlur() {
      this.changeConfiguration({
        translations: { ...this.form },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-bookings-form .row {
  margin-bottom: 2rem;
}
</style>
